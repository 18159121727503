import { version } from '../../../common/version.json'
import * as Protocol from './shared-worker-protocol'

interface SharedWorker extends Worker {
  port: MessagePort
}

// eslint-disable-next-line no-var
declare var SharedWorker: {
  prototype: SharedWorker
  new (stringUrl: string, name?: string): SharedWorker
}

export class SharedWorkerClient<TClientMessage, TWorkerMessage> {
  private readonly name: string
  private readonly w: SharedWorker

  constructor(readonly src: string) {
    this.name = `[SharedWorkerClient: ${this.src}]`
    this.w = new SharedWorker(`${location.origin}/${src}.js`, `${src}-${version}`)

    this.disconnect = this.disconnect.bind(this)
    this.debug = this.debug.bind(this)
    this.info = this.info.bind(this)
  }

  postMessage = (message: TClientMessage) => this.w.port.postMessage(new Protocol.Custom(message))

  start = (callback: (payload: TWorkerMessage) => any) => {
    this.w.port.onmessage = (e: MessageEvent) => {
      this.debug('onmessage', e.data)

      const payload = e.data as Protocol.WorkerMessage<TWorkerMessage>
      switch (payload._tag) {
        case 'Custom':
          callback(payload.wrapped)
          break
      }
    }
    // port is now started, initialize rest of the stuff
    addEventListener('beforeunload', this.disconnect)
  }

  terminate = () => this.w.port.postMessage(new Protocol.TerminateWorker())

  private disconnect = () => this.w.port.postMessage(new Protocol.DisconnectClient())
  private debug = (...args: any[]) => console.debug(this.name, ...args)
  private info = (...args: any[]) => console.info(this.name, ...args)
}
