import { EquipmentGroupStId, FlexibleScope, Selections, ModuleName, GroupKind } from '../../../../../types/types'

export type ModuleScopes = Record<ModuleName, FlexibleScope>

export interface FlexibleModuleScopes {
  readonly rating: FlexibleScope
  readonly moduleScopes: ModuleScopes
}

export interface FlexibleRatingsAndSelections {
  readonly ratings: FlexibleModuleScopes
  readonly selections: Selections
}

export interface FlexibleCriticalities {
  readonly normal: FlexibleRatingsAndSelections | null
  readonly critical: FlexibleRatingsAndSelections | null
}

export class ElevatorsEscalatorsApfs {
  static fromObject(obj: {
    elevator: FlexibleCriticalities | null
    escalator: FlexibleCriticalities | null
    resiflow: FlexibleCriticalities | null
  }) {
    return new ElevatorsEscalatorsApfs(obj.elevator, obj.escalator, obj.resiflow)
  }

  private constructor(
    readonly elevator: FlexibleCriticalities | null,
    readonly escalator: FlexibleCriticalities | null,
    readonly resiflow: FlexibleCriticalities | null
  ) {}
}

export class Doors {
  static fromObject({ door }: { door: FlexibleCriticalities | null }) {
    return new Doors(door)
  }

  private constructor(readonly door: FlexibleCriticalities | null) {}
}

export interface FlexibleGroup<T> {
  readonly salesToolId: EquipmentGroupStId
  readonly name: string
  readonly groupKind: GroupKind
  readonly equipment: T
}

export type Groups<T> = Record<EquipmentGroupStId, FlexibleGroup<T>>

export class DoorGroups {
  readonly _tag: 'DoorGroups' = 'DoorGroups'
  constructor(readonly groups: Groups<Doors>) {}
}

export class ElevatorEscalatorApfGroups {
  readonly _tag: 'ElevatorEscalatorApfGroups' = 'ElevatorEscalatorApfGroups'
  constructor(readonly groups: Groups<ElevatorsEscalatorsApfs>) {}
}
