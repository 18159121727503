// client originated messages

export class DisconnectClient {
  readonly _tag: 'DisconnectClient' = 'DisconnectClient'
}
export class TerminateWorker {
  readonly _tag: 'TerminateWorker' = 'TerminateWorker'
}
export type ClientMessage<T> = DisconnectClient | Custom<T> | TerminateWorker

// worker originated messages

export type WorkerMessage<T> = Custom<T>

// shared

export class Custom<T> {
  readonly _tag: 'Custom' = 'Custom'
  constructor(readonly wrapped: T) {}
}
