import { Validation } from 'fp-ts/lib/Validation'
import {
  EquipmentGroupStId,
  Offer,
  OfferingInteractionQuestion,
  OfferingQuestion,
  SapKey,
  Scope,
  Selection,
  Selections
} from '../../../../types/types'
import { DependencyError } from './error'
import { Criticality } from 'nemo-pricing'

export interface DependencyQuestion extends OfferingQuestion {
  scope: Scope
}

export type OfferingOrInteractionQuestion = OfferingQuestion | OfferingInteractionQuestion

export interface ChoiceState {
  enabled: boolean
  failureReasons: DependencyError
  required: boolean
  value: string
  checked: boolean
}

export interface QuestionChoiceState {
  readonly choices: ChoiceState[]
  readonly hasValidAnswer: boolean
  readonly enabled: boolean
  readonly checked: boolean
  readonly value: Selection
}

export interface AnswersAndChoiceStates {
  answers: Selections
  states: PRecord<SapKey, ChoiceState[]>
}

export interface DependencyInputs {
  answers: Selections
  offer: Offer
  groupId: EquipmentGroupStId | null // Interaction questions don't have a group
  criticality: Criticality | null
}

// eslint-disable-next-line  no-shadow
export enum TechnicalDependencyKey {
  technicalPlatform = 'MBM_TECH_PLATFORM',
  apfComponent = 'APF_COMPONENT',
  koneConnectivityDeviceType = 'KONE_CONNECTIVITY_DEVICE_TYPE'
}

export type DependencyKey = SapKey | TechnicalDependencyKey

type Answer = ['answer', DependencyKey, string[]]
type NotAnswer = ['notAnswer', DependencyKey, string[]]
type NoAnswer = ['noanswer', SapKey]
type Some = ['some', SapKey]
type None = ['none', SapKey]
type And = ['and', Expr, Expr]
type Or = ['or', Expr, Expr]
type Any = ['any', ExprArray]

// Workaround for recursive type aliases, see https://stackoverflow.com/q/51657815/3425536
interface ExprArray extends Array<Expr> {}
interface AndWrapper extends And {}
interface OrWrapper extends Or {}

export type Expr = Answer | NotAnswer | NoAnswer | Some | None | AndWrapper | OrWrapper | Any

export type ValidationResult = Validation<DependencyError, boolean>

export type Test = (inputs: DependencyInputs) => ValidationResult

export class Toggle {
  readonly _tag: 'toggle' = 'toggle'
  constructor(readonly affectedChoices: string[], readonly test: Test) {}
}

export class SetHidden {
  readonly _tag: 'set' = 'set'
  constructor(readonly questionKey: SapKey, readonly value: string, readonly test: Test) {}
}

export class Switch {
  readonly _tag: 'switch' = 'switch'
  constructor(readonly questionKey: SapKey, readonly values: string[], readonly test: Test) {}
}

export class Required {
  readonly _tag: 'required' = 'required'
  constructor(readonly test: Test, readonly values?: string[]) {}
}

export type Dependency = Toggle | SetHidden | Switch | Required
