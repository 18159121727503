import * as R from 'ramda'
import {
  Criticality,
  EquipmentGroupStId,
  OfferingQuestion,
  Selections,
  Selection,
  Scope,
  ModuleName,
  QuickTenderScope,
  OfferingInteractionQuestion,
  InteractionScope,
  Offer,
  OfferSelectionType,
  OfferingQuestions,
  ValueAddedServicesAddendumScope,
  GSMCampaignScope
} from '../../../../types/types'
import * as deps from '../dependencies/dependencies'
import {
  Offering,
  FlexibleCriticalities,
  FlexibleRatingsAndSelections,
  Flexible,
  QuickTender,
  QuickTenderWithRatings,
  QuickTenderGroupWithRating,
  QuickTenderEquipmentCategory,
  ValueAddedServicesAddendum,
  ElevatorsEscalatorsApfs,
  Doors,
  ElevatorEscalatorApfGroups,
  DoorGroups,
  Interaction,
  ResiFlowInteraction
} from './types'
import { getDefaultsForScope, getInitialAnswersForInteraction } from './selections'
import { updateQuickTenderGroupSelections } from './quick-tender-with-ratings'
import {
  getInteractionOfferingQuestions,
  getFlexibleQuestionsForModule,
  getGroupOfferingQuestionsByOffering
} from './questions'
import { match } from './flexible'

export function repairOfferingSelections(allQuestions: OfferingQuestions, offering: Offering, offer: Offer): Offering {
  const groupQuestions = getGroupOfferingQuestionsByOffering(allQuestions, offering, offer.isGSMOffer)
  const interactionQuestions = getInteractionOfferingQuestions(allQuestions, offering, offer)
  const applyModuleScopes = (scopes: Record<ModuleName, Scope>, defaultScope: Scope) =>
    Object.keys(scopes).reduce((s, m: ModuleName) => (s[m] === null ? R.assoc(m, defaultScope, s) : s), scopes)

  function inCriticality(
    questions: OfferingQuestion[],
    x: FlexibleRatingsAndSelections,
    groupId: EquipmentGroupStId,
    criticality: Criticality
  ) {
    const scopes = applyModuleScopes(x.ratings.moduleScopes, x.ratings.rating)
    return {
      ratings: x.ratings,
      selections: repairSelections(questions, scopes, x.selections, offer, groupId, criticality)
    }
  }

  function inCategory(questions: OfferingQuestion[], x: FlexibleCriticalities, groupId: EquipmentGroupStId) {
    return {
      normal: x.normal ? inCriticality(questions, x.normal, groupId, 'normal') : null,
      critical: x.critical ? inCriticality(questions, x.critical, groupId, 'critical') : null
    }
  }

  function repairQuickTenderSelections(
    scope: QuickTenderScope,
    group: QuickTenderGroupWithRating
  ): QuickTenderEquipmentCategory {
    const moduleScopes: Record<ModuleName, QuickTenderScope | null> = {
      basics: scope,
      technical: scope,
      performance: scope,
      value_added_services: scope,
      extra_services: scope,
      resiflow: null,
      gsm_campaign: scope // TODO: Remove as part of GSM Clean up
    }
    const repairedGroup = R.pipe(
      updateQuickTenderGroupSelections(
        xs => repairSelections(groupQuestions.elevator, moduleScopes, xs, offer, group.salesToolId, 'normal'),
        'normal'
      ),
      updateQuickTenderGroupSelections(
        xs => repairSelections(groupQuestions.elevator, moduleScopes, xs, offer, group.salesToolId, 'critical'),
        'critical'
      )
    )(group)
    return repairedGroup.selections
  }

  switch (offering._tag) {
    case 'Flexible':
      const offeringInteraction = offering.interaction
        ? (offering.interaction as Interaction)
        : (null as ResiFlowInteraction)

      const groups = match<ElevatorEscalatorApfGroups | DoorGroups>(offering.groups, {
        ElevatorEscalatorApfGroups: gs =>
          new ElevatorEscalatorApfGroups(
            R.map(g => {
              const equipment = ElevatorsEscalatorsApfs.fromObject({
                elevator:
                  g.equipment.elevator === null
                    ? null
                    : inCategory(groupQuestions.elevator, g.equipment.elevator, g.salesToolId),
                escalator:
                  g.equipment.escalator === null
                    ? null
                    : inCategory(groupQuestions.escalator, g.equipment.escalator, g.salesToolId),
                resiflow:
                  g.equipment.resiflow === null
                    ? null
                    : inCategory(groupQuestions.resiflow, g.equipment.resiflow, g.salesToolId)
              })

              return {
                name: g.name,
                groupKind: g.groupKind,
                salesToolId: g.salesToolId,
                equipment
              }
            }, gs)
          ),
        DoorGroups: gs =>
          new DoorGroups(
            R.map(
              g => ({
                name: g.name,
                salesToolId: g.salesToolId,
                groupKind: g.groupKind,
                equipment: Doors.fromObject({
                  door: g.equipment.door ? inCategory(groupQuestions.door, g.equipment.door, g.salesToolId) : null
                })
              }),
              gs
            )
          )
      })
      return offeringInteraction
        ? new Flexible(groups, {
            scope: offeringInteraction.scope,
            selections: repairInteractionSelections(
              interactionQuestions,
              offeringInteraction.scope,
              offeringInteraction.selections,
              offer
            )
          } as Interaction)
        : new Flexible(groups, null as ResiFlowInteraction)
    case 'QuickTender':
      const moduleScopes: Record<ModuleName, QuickTenderScope | null> = {
        basics: offering.scope,
        technical: offering.scope,
        performance: offering.scope,
        value_added_services: offering.scope,
        extra_services: offering.scope,
        resiflow: null,
        gsm_campaign: offering.scope // TODO: Remove as part of GSM Clean up
      }
      return new QuickTender(
        R.map(
          group => ({
            salesToolId: group.salesToolId,
            name: group.name,
            groupKind: group.groupKind,
            selections: repairSelections(
              groupQuestions.elevator,
              moduleScopes,
              group.selections,
              offer,
              group.salesToolId,
              null
            )
          }),
          offering.groups
        ),
        offering.scope,
        repairInteractionSelections(interactionQuestions, offering.scope, offering.interactionSelections, offer)
      )
    case 'QuickTenderWithRatings':
      return new QuickTenderWithRatings(
        R.map(
          g => ({
            name: g.name,
            salesToolId: g.salesToolId,
            groupKind: g.groupKind,
            selections: repairQuickTenderSelections(offering.scope, g)
          }),
          offering.groups
        ),
        offering.scope,
        repairInteractionSelections(interactionQuestions, offering.scope, offering.interactionSelections, offer),
        offering.selectedInteractionRating
      )
    case 'ValueAddedServicesAddendum':
      const mScopes: Record<ModuleName, ValueAddedServicesAddendumScope | null | GSMCampaignScope> = offer.isGSMOffer
        ? {
            // TODO: Remove as part of GSM Clean up
            basics: 'gsm_campaign',
            technical: 'gsm_campaign',
            performance: 'gsm_campaign',
            value_added_services: 'gsm_campaign',
            extra_services: null,
            resiflow: null,
            gsm_campaign: 'gsm_campaign'
          }
        : {
            basics: null,
            technical: null,
            performance: null,
            value_added_services: 'value_added_services_addendum',
            extra_services: null,
            resiflow: null,
            gsm_campaign: 'value_added_services_addendum'
          }
      return new ValueAddedServicesAddendum(
        R.map(
          group => ({
            salesToolId: group.salesToolId,
            name: group.name,
            groupKind: group.groupKind,
            selections: repairSelections(
              groupQuestions.elevator,
              mScopes,
              group.selections,
              offer,
              group.salesToolId,
              null
            )
          }),
          offering.groups
        ),
        repairInteractionSelections(
          interactionQuestions,
          'value_added_services_addendum',
          offering.interactionSelections,
          offer
        )
      )
    case 'gsm_campaign':
    case 'QuickTenderScopePending':
    case 'NoRatings':
      return offering
  }
}

export function repairSelections(
  questions: OfferingQuestion[],
  scopes: Record<ModuleName, Scope | null>,
  selections: Selections,
  offer: Offer,
  groupId: EquipmentGroupStId,
  criticality: Criticality | null
) {
  const typeSelections = R.reduce(
    (acc, [k, v]) => {
      const question: OfferingQuestion | undefined = questions.find(item => item.key === k)

      if (!question) {
        return acc
      }

      const scope = R.prop(question.module, scopes)
      return scope === null || scope === undefined
        ? acc
        : R.assoc(
            k,
            updateSelection(
              question.type,
              question.default_values[scope],
              question.selections[scope] || [],
              question.optional,
              v!
            ),
            acc
          )
    },
    {},
    R.toPairs(selections)
  )

  const defaults: Selections = {
    ...(scopes.basics ? getDefaultsForScope(scopes.basics, getFlexibleQuestionsForModule('basics', questions)) : {}),
    ...(scopes.technical
      ? getDefaultsForScope(scopes.technical, getFlexibleQuestionsForModule('technical', questions))
      : {}),
    ...(scopes.performance
      ? getDefaultsForScope(scopes.performance, getFlexibleQuestionsForModule('performance', questions))
      : {}),
    ...(scopes.value_added_services
      ? getDefaultsForScope(
          scopes.value_added_services,
          getFlexibleQuestionsForModule('value_added_services', questions)
        )
      : {}),
    ...(scopes.extra_services
      ? getDefaultsForScope(scopes.extra_services, getFlexibleQuestionsForModule('extra_services', questions))
      : {}),
    ...(scopes.resiflow
      ? getDefaultsForScope(scopes.resiflow, getFlexibleQuestionsForModule('resiflow', questions))
      : {}),
    ...(scopes.gsm_campaign
      ? getDefaultsForScope(scopes.gsm_campaign, getFlexibleQuestionsForModule('gsm_campaign', questions)) // TODO: Remove as part of GSM Clean up
      : {})
  }

  const answers = { ...defaults, ...typeSelections }
  return deps.applySetterDependencies(questions, answers, offer, groupId, criticality)
}

function repairInteractionSelections(
  questions: OfferingInteractionQuestion[],
  scope: InteractionScope,
  selections: Selections,
  offer: Offer
) {
  const typeSelections = R.reduce(
    (acc, [k, v]) => {
      const question: OfferingInteractionQuestion | undefined = questions.find(item => item.key === k)

      if (question) {
        return R.assoc(
          k,
          updateSelection(
            question.type,
            question.default_values[scope],
            question.selections[scope] || [],
            question.optional,
            v! // TODO `!` can be removed when Selections is a Record
          ),
          acc
        )
      } else {
        return acc
      }
    },
    {},
    R.toPairs(selections)
  )

  const answers = { ...getInitialAnswersForInteraction(questions, scope, offer), ...typeSelections }
  return deps.applySetterDependencies(questions, answers, offer, null, null)
}

function updateSelection(
  type: OfferSelectionType,
  defaultValue: any,
  choices: string[],
  optional: boolean,
  answer: Selection
) {
  if (optional && answer === null) {
    return answer
  } else if (type === 'multiple') {
    const selectedAllowed = R.intersection((answer as string[]) || [], choices || [])
    // If none of the selected choices are no longer available, pick all
    // of the default values.
    return selectedAllowed.length === 0 ? defaultValue : selectedAllowed
  } else if (!R.contains(answer, choices)) {
    return typeof defaultValue !== 'undefined' ? defaultValue : null
  }

  return answer
}
