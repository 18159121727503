import { some, none } from 'fp-ts/lib/Option'
import * as i18n from 'i18next'
import * as R from 'ramda'
import {
  Criticality,
  EquipmentCategory,
  EquipmentGroupStId,
  Module,
  ModuleName,
  Offer,
  OfferingQuestion,
  OfferSelectionType,
  OfferSelectionVisibility,
  SapKey,
  Scope,
  OfferingQuestions,
  Selections,
  InteractionScope,
  GroupKind
} from '../../../../../types/types'
import * as deps from '../../../nemo/dependencies/dependencies'
import { QuestionChoiceState } from '../../../nemo/dependencies/types'
import {
  CriticalityPath,
  Offering,
  match,
  ElevatorEscalatorApfGroups,
  DoorGroups,
  Interaction,
  ResiFlowInteraction,
  Groups,
  ElevatorsEscalatorsApfs,
  FlexibleGroup
} from '../../../nemo/offering/types'
import * as Flex from '../../../nemo/offering/flexible'
import {
  getInteractionOfferingQuestions,
  getGroupOfferingQuestionsByOffering,
  GroupOfferingQuestionsByEquipmentCategory
} from '../../../nemo/offering/questions'
import * as QTWithRatings from '../../../nemo/offering/quick-tender-with-ratings'
import * as QT from '../../../nemo/offering/quick-tender'
import * as VAS from '../../../nemo/offering/value-added-services'
import {
  getEquipmentCategoryState,
  EquipmentCategoryValidationState,
  updateGroupValidationState,
  GroupValidationStates,
  QuestionValidationState
} from '../../../nemo/offering/validation'
import { Optional } from 'monocle-ts'

const modules: ModuleName[] = [
  'basics',
  'technical',
  'performance',
  'value_added_services',
  'extra_services',
  'resiflow',
  'gsm_campaign' // TODO: Remove as part of GSM Clean up
]

// eslint-disable-next-line  no-shadow
export enum moduleDisplayOrder {
  'basics',
  'technical',
  'performance',
  'extra_services',
  'value_added_services',
  'interaction',
  'resiflow',
  'gsm_campaign' // TODO: Remove as part of GSM Clean up
}

// eslint-disable-next-line  no-shadow
enum equipmentDisplayOrder {
  'elevator',
  'escalator',
  'door',
  'resiflow'
}

interface ModuleContainer {
  readonly module: Module
}

export const baseModuleDisplayComparator = (a: Module, b: Module) => moduleDisplayOrder[a] - moduleDisplayOrder[b]
export const moduleDisplayOrderComparator = (a: ModuleContainer, b: ModuleContainer): number =>
  baseModuleDisplayComparator(a.module, b.module)

export const equipmentDisplayOrderComparator = (a: EquipmentCategory, b: EquipmentCategory) =>
  equipmentDisplayOrder[a] - equipmentDisplayOrder[b]

export type GroupStates = Record<EquipmentGroupStId, Group>
export type GroupStatesByCategory = Record<EquipmentGroupStId, CategoryGroup>

interface GroupI {
  readonly name: string
  readonly groupId: EquipmentGroupStId
  readonly groupKind: GroupKind
  readonly errors: QuestionError[]
  readonly isValid: boolean
}

type ValidationState = Record<EquipmentCategory, EquipmentCategoryValidationState | null>
type ModuleStates = Record<EquipmentCategory, ModuleState[] | null>
export interface Group extends GroupI {
  readonly validationState: ValidationState
  readonly moduleStates: ModuleStates
}

export type CategoryModuleStates = Record<ModuleName, ModuleState>

export interface CategoryGroup extends GroupI {
  readonly validationState: EquipmentCategoryValidationState | null
  readonly moduleStates: CategoryModuleStates
}

const groupL = (groupId: EquipmentGroupStId) =>
  new Optional<GroupStates, Group>(
    s => (s[groupId] ? some(s[groupId]) : none),
    a => s => ({ ...s, [groupId]: a })
  )

export type EquipmentCountsByGroup = Record<EquipmentGroupStId, EquipmentCountsByCategory>

export interface EquipmentCountsByCategory {
  building: number
  elevator: {
    normal: number
  }
  escalator: {
    normal: number
  }
  door: {
    normal: number
  }
  resiflow: {
    normal: number
  }
}

export interface InteractionUiState {
  readonly key: SapKey
  readonly type: OfferSelectionType
  readonly optional: boolean
  readonly ui_order: number
  readonly visibility: OfferSelectionVisibility
  readonly choices: string[]
  readonly states: QuestionChoiceState | null
}

export interface QuestionState {
  readonly key: SapKey
  readonly type: OfferSelectionType
  readonly module: ModuleName
  readonly optional: boolean
  readonly ui_order: number
  readonly visibility: OfferSelectionVisibility
  readonly choices: string[]
  readonly statesByCriticality: {
    normal: QuestionChoiceState | null
  }
}

export interface ModuleCriticality {
  criticality: Criticality
  isValid: boolean
  scope: Scope | null
}

interface ModuleCriticalities {
  normal: ModuleCriticality | null
}

export interface ModuleState {
  module: ModuleName
  criticalities: ModuleCriticalities
  questions: QuestionState[]
}

export interface Answer {
  category: EquipmentCategory
  criticality: Criticality
  question: QuestionState
  value: string | null
}

export interface InteractionQuestionError {
  module: 'interaction'
  questionKey: SapKey
}

export interface QuestionError {
  groupId: EquipmentGroupStId
  category: EquipmentCategory
  criticality: Criticality
  groupName: string
  module: ModuleName
  questionKey: SapKey
  vasSubCategory?: string
}

const isGroupValid = (moduleStates: ModuleStates): boolean =>
  !R.any(
    moduleState => (moduleState.criticalities.normal !== null ? !moduleState.criticalities.normal.isValid : false),
    [
      ...(moduleStates.elevator || []),
      ...(moduleStates.escalator || []),
      ...(moduleStates.door || []),
      ...(moduleStates.resiflow || [])
    ]
  )

export const getInteractionErrors = (interactionStates: InteractionUiState[]) =>
  R.reduce<InteractionUiState, InteractionQuestionError[]>(
    (iErrors, uiState) => {
      const { states, key } = uiState
      if (states && !states.hasValidAnswer) {
        return R.append(
          {
            module: 'interaction',
            questionKey: key
          },
          iErrors
        )
      }
      return iErrors
    },
    [],
    interactionStates
  )

const getGroupErrors = (allModuleStates: ModuleStates, groupId: string, groupName: string): QuestionError[] => {
  function getCriticalityError(
    state: QuestionState,
    category: EquipmentCategory,
    criticality: 'normal'
  ): QuestionError | null {
    return state.statesByCriticality[criticality] !== null && !state.statesByCriticality[criticality]!.hasValidAnswer
      ? {
          groupId,
          category,
          criticality,
          groupName,
          module: state.module,
          questionKey: state.key
        }
      : null
  }

  function getCategoryErrors(moduleStates: ModuleState[], category: EquipmentCategory): QuestionError[] {
    return R.reduce(
      (errors: QuestionError[], moduleState: ModuleState) =>
        errors.concat(
          R.reduce(
            (moduleErrors: QuestionError[], questionState: QuestionState) => {
              const normalError = getCriticalityError(questionState, category, 'normal')
              return moduleErrors.concat(normalError || [])
            },
            [],
            moduleState.questions
          )
        ),
      [],
      moduleStates
    )
  }

  return [
    ...getCategoryErrors(allModuleStates.elevator || [], 'elevator'),
    ...getCategoryErrors(allModuleStates.escalator || [], 'escalator'),
    ...getCategoryErrors(allModuleStates.door || [], 'door'),
    ...getCategoryErrors(allModuleStates.resiflow || [], 'resiflow')
  ]
}

export function initGroupStates(
  questions: GroupOfferingQuestionsByEquipmentCategory,
  states: GroupValidationStates
): GroupStates {
  return R.map(group => {
    const elevatorStates = group.elevator !== null ? getModuleStates(questions.elevator, group.elevator) : null
    const escalatorStates = group.escalator !== null ? getModuleStates(questions.escalator, group.escalator) : null
    const doorStates = group.door !== null ? getModuleStates(questions.door, group.door) : null
    const resiflowStates = group.resiflow !== null ? getModuleStates(questions.resiflow, group.resiflow) : null
    const allStates: ModuleStates = {
      elevator: elevatorStates,
      escalator: escalatorStates,
      door: doorStates,
      resiflow: resiflowStates
    }
    const isValid = isGroupValid(allStates)
    return {
      groupId: group.groupId,
      name: group.name,
      groupKind: group.groupKind,
      validationState: {
        elevator: group.elevator,
        escalator: group.escalator,
        door: group.door,
        resiflow: group.resiflow
      },
      errors: isValid ? [] : getGroupErrors(allStates, group.groupId, group.name),
      moduleStates: {
        elevator: elevatorStates,
        escalator: escalatorStates,
        door: doorStates,
        resiflow: resiflowStates
      },
      isValid
    }
  }, states)
}

export function updateGroupStates(
  offeringQuestions: OfferingQuestions,
  path: CriticalityPath,
  statesByGroup: GroupStates,
  offering: Offering,
  offer: Offer
): GroupStates {
  return groupL(path.groupId).modify(groupState => {
    const groupValidationState = {
      name: groupState.name,
      groupId: groupState.groupId,
      groupKind: groupState.groupKind,
      elevator: groupState.validationState.elevator,
      escalator: groupState.validationState.escalator,
      door: groupState.validationState.door,
      resiflow: groupState.validationState.resiflow
    }
    // TODO: Remove as part of GSM Clean up
    const groupOfferingQuestions = getGroupOfferingQuestionsByOffering(offeringQuestions, offering, offer.isGSMOffer)[
      path.equipmentCategory
    ]
    const validationState = updateGroupValidationState(
      groupOfferingQuestions,
      groupValidationState,
      offering,
      path,
      offer
    )
    return getEquipmentCategoryState(path.equipmentCategory, validationState)
      .map(state => {
        const moduleStates = {
          ...groupState.moduleStates,
          [path.equipmentCategory]: getModuleStates(groupOfferingQuestions, state)
        }
        const isValid = isGroupValid(moduleStates)

        return {
          ...groupState,
          validationState: {
            elevator: validationState.elevator,
            escalator: validationState.escalator,
            door: validationState.door,
            resiflow: validationState.resiflow
          },
          errors: isValid ? [] : getGroupErrors(moduleStates, groupState.groupId, groupState.name),
          moduleStates,
          isValid
        }
      })
      .getOrElse(() => groupState)
  })(statesByGroup)
}

export function initInteractionValidationStates(
  questions: OfferingQuestions,
  offering: Offering,
  offer: Offer
): InteractionUiState[] {
  const interactionQuestions = getInteractionOfferingQuestions(questions, offering, offer) // TODO: Remove as part of GSM Clean up

  function getStates(
    scope: InteractionScope,
    selections: Selections,
    selectionsByGroup: Record<EquipmentGroupStId, Selections[]>
  ) {
    const states = deps.getInteractionChoiceStates(interactionQuestions, scope, selections, selectionsByGroup, offer)
    return R.chain(question => {
      const { choices, key, optional, type, ui_order, visibility } = question
      const questionState = deps.getQuestionUiState(question, states.states[key]!, states.answers)

      const visibleOrHasErrors = visibility !== 'none' || !questionState.hasValidAnswer

      return visibleOrHasErrors
        ? [
            {
              choices,
              key,
              optional,
              type,
              ui_order,
              visibility,
              states: questionState
            }
          ]
        : []
    }, interactionQuestions)
  }

  function excludePeopleflowGroup(
    offerGroups: ElevatorEscalatorApfGroups | DoorGroups
  ): ElevatorEscalatorApfGroups | DoorGroups {
    return offerGroups._tag === 'DoorGroups'
      ? offerGroups
      : {
          ...offerGroups,
          groups: R.reduce(
            (groups: Groups<ElevatorsEscalatorsApfs>, group: FlexibleGroup<ElevatorsEscalatorsApfs>) =>
              R.assoc(group.salesToolId, group, groups),
            {},
            R.filter(group => group.groupKind !== 'people_flow', Object.values(offerGroups.groups))
          )
        }
  }

  return match(offering, {
    NoRatings: () => [],
    Flexible: (groups: ElevatorEscalatorApfGroups | DoorGroups, interaction: Interaction | ResiFlowInteraction) => {
      if (interaction) {
        return getStates(interaction.scope, interaction.selections, Flex.allSelections(excludePeopleflowGroup(groups)))
      }
      return []
    },
    QuickTender: (groups, scope, interactionSelections) =>
      getStates(scope, interactionSelections, QT.allSelections(groups)),
    QuickTenderWithRatings: (groups, scope, interactionSelections, _) =>
      getStates(scope, interactionSelections, QTWithRatings.allSelections(groups)),
    QuickTenderScopePending: _ => [],
    ValueAddedServices: (groups, interactionSelections) =>
      getStates('value_added_services_addendum', interactionSelections, VAS.allSelections(groups)),
    GSMCampaign: (groups, interactionSelections) =>
      getStates('gsm_campaign', interactionSelections, VAS.allSelections(groups)) // TODO: Remove as part of GSM Clean up
  })
}

function getModuleStates(questions: OfferingQuestion[], states: EquipmentCategoryValidationState): ModuleState[] {
  const questionUIStates = getQuestionStates(questions, states)
  const groupedByModule = R.groupBy(q => q.module, questionUIStates)
  return modules.map(module => {
    const moduleQuestions = groupedByModule[module] || []
    const criticalities = {
      normal: states.normal
        ? {
            criticality: 'normal' as Criticality,
            isValid: !R.any(
              m => (m.statesByCriticality.normal ? !m.statesByCriticality.normal.hasValidAnswer : false),
              moduleQuestions
            ),
            scope: states.normal.ratings[module]
          }
        : null
    }
    return {
      criticalities,
      module,
      questions: moduleQuestions
    }
  })
}

export function initQuestionState(
  question: OfferingQuestion,
  states: EquipmentCategoryValidationState,
  errorInit?: boolean
): QuestionState[] {
  const { choices, key, module, optional, type, ui_order, visibility } = question
  function choiceStateForQuestion(state: QuestionValidationState): QuestionChoiceState {
    return deps.getQuestionUiState(question, state.choiceStates, {
      [key]: state.answer
    })
  }

  const statesByCriticality = {
    normal: states.normal ? choiceStateForQuestion(states.normal.selections[key]!) : null
  }
  const normalValid = statesByCriticality.normal ? statesByCriticality.normal.hasValidAnswer : true
  const visibleOrHasErrors = visibility !== 'none' || !normalValid || errorInit

  return visibleOrHasErrors
    ? [
        {
          choices,
          key,
          module,
          optional,
          statesByCriticality,
          type,
          ui_order,
          visibility
        }
      ]
    : []
}

function getQuestionStates(questions: OfferingQuestion[], states: EquipmentCategoryValidationState): QuestionState[] {
  return R.chain(question => initQuestionState(question, states), questions)
}

export const getScopeTitle = (module: Module | 'interaction', scope: Scope | null): string =>
  !scope ? i18n.t('scopes.chooseScope') : i18n.t(`scopes.${module}.${scope}.title`)
