import * as Protocol from './dedicated-worker-protocol'

export class DedicatedWorkerClient<TClientMessage, TWorkerMessage> {
  private readonly name: string
  private readonly w: Worker

  constructor(readonly src: string) {
    this.name = `[DedicatedWorkerClient: ${this.src}]`
    this.w = new Worker(`${location.origin}/${src}.js`)

    this.debug = this.debug.bind(this)
    this.info = this.info.bind(this)
  }

  postMessage = (message: TClientMessage) => this.w.postMessage(new Protocol.Custom(message))

  start = (callback: (payload: TWorkerMessage) => any) => {
    this.w.onmessage = (e: MessageEvent) => {
      this.debug('onmessage', e.data)

      const payload = e.data as Protocol.WorkerMessage<TWorkerMessage>
      switch (payload._tag) {
        case 'Custom':
          callback(payload.wrapped)
          break
      }
    }
    this.w.postMessage(new Protocol.Start())
  }

  terminate = () => this.w.postMessage(new Protocol.TerminateWorker())

  private debug = (...args: any[]) => console.debug(this.name, ...args)
  private info = (...args: any[]) => console.info(this.name, ...args)
}
