import { isRunningTests } from '../../common/test-utils'
import Bugsnag, { Client } from '@bugsnag/js'
import { version } from '../../common/version.json'
import BugsnagPluginReact from '@bugsnag/plugin-react'

type NotifiableError = Error | { errorClass: string; errorMessage: string } | { name: string; message: string }

// This is a stateful module.
let currentUser: User | undefined
let bugsnagInstance: Client | undefined
const environment = window.environment

function createInstance() {
  // eslint-disable-next-line no-shadow, @typescript-eslint/no-var-requires
  return Bugsnag.start({
    apiKey: '532408ebb3e4ae25bc22e7567d792cc2',
    appVersion: version,
    releaseStage: environment,
    user: {
      name: 'Before user initialization',
      email: 'noemail@kone.com',
      id: '1'
    },
    plugins: [new BugsnagPluginReact()],
    autoTrackSessions: true,
    autoDetectErrors: false,
    enabledReleaseStages: [
      'prod-eu',
      'prod-china',
      'pre-eu',
      'pre-china',
      'qa-qa',
      'demo-qa',
      'qa-mqa',
      'demo-mqa',
      'qa-qa1',
      'demo-qa1',
      'qa-chinaqa',
      'demo-chinaqa'
    ],
    onError: (event: any) => {
      event.context = event.errorMessage
      if (currentUser) {
        event.setUser(currentUser?.id, currentUser?.email, currentUser?.name)
      }
    }
  })
}

function initialize() {
  const isDevEnv = environment === 'development'
  if (isRunningTests() || isDevEnv) {
    return
  }
  bugsnagInstance = createInstance()
}

export function notify(error: NotifiableError, event: Record<any, any>) {
  if (bugsnagInstance === undefined) {
    console.error(error, event)
    return false
  }
  return Bugsnag.notify(error)
}

export interface User {
  id: string
  name: string
  email: string
  frontlineId: string
}

export function setUser(user: User) {
  currentUser = user
}

initialize()
