import * as Task from 'data.task'
import * as R from 'ramda'
import { offerIsSyncing, isOfferUnsaved, isOfferFinalized } from '../../../common/offer-state-predicates'
import { updateDb } from './db-versions'
import Dexie from 'dexie'
import {
  ClientOffer,
  Offer,
  OpportunityCustomer,
  CustomerSfId,
  OpportunitySfId,
  OfferPreviousVersion,
  Opportunity
} from '../../../types/types'

class ClientDatabase extends Dexie {
  offers: Dexie.Table<ClientOffer, OpportunitySfId>
  accounts: Dexie.Table<OpportunityCustomer, CustomerSfId>
  // TODO: add typings to config
  config: Dexie.Table<Record<any, any>, string>
  constructor() {
    super('kcc', { autoOpen: true })
    this.on('versionchange', event => {
      console.info(`IndexedDB is updating. Old version: ${event.oldVersion}, new version: ${event.newVersion}`)
      updateDb(this)
    })
    updateDb(this)
  }
}

const db = new ClientDatabase()

const fromPromiseCatch = <T>(p: () => Dexie.Promise<T>) =>
  new Task<T>((reject, resolve) => {
    p().then(resolve).catch(reject)
  })

export const clearOffers = () => {
  db.offers.clear()
}  

export const clear = () => {
  db.offers.clear()
  db.accounts.clear()
  db.config.clear()
}

export const updateConfigurations = (list: Array<Record<any, any>>) => fromPromiseCatch(() => db.config.bulkPut(list))
export const removeConfig = (id: string) => fromPromiseCatch(() => db.config.delete(id))
export const updateConfig = (id: string, value: Record<any, any>) =>
  fromPromiseCatch(() => db.config.put(R.assoc('id', id, value)))

const fetchConfigPromise = (name: string) =>
  db.config
    .where('id')
    .equals(name)
    .toArray()
    .then(config => R.head(config) || {})
export const fetchConfig = (name: string) => fromPromiseCatch(() => fetchConfigPromise(name))

export const fetchAccounts = () => fromPromiseCatch(() => db.accounts.toArray())

export const removeNotOwnedOffers = (opportunityIds: OpportunitySfId[]) =>
  fromPromiseCatch(() =>
    db.offers
      .where('opportunityId')
      .noneOf(opportunityIds)
      .delete()
      .then(count => {
        console.info(`removed ${count} offer(s) that are not active and/or not owned by the current user`)
      })
  )

export const updateAccounts = (accounts: OpportunityCustomer[]) =>
  fromPromiseCatch(() => db.accounts.clear().then(_ => db.accounts.bulkPut(accounts)))

export const updateAccount = (account: OpportunityCustomer, opportunity: Opportunity) =>
  fromPromiseCatch(() =>
    db.transaction('rw', db.accounts, () =>
      db.accounts
        .where('id')
        .equals(account.id)
        .toArray()
        .then(xs => R.head(xs || []))
        .then((dbAccount: OpportunityCustomer) => {
          const opportunities = dbAccount!.opportunities.map(opp => {
            if (opp.id === opportunity.id) {
              return opportunity
            }
            return opp
          })
          const updatedAccount = {
            ...account,
            opportunities
          }
          return db.accounts.put(updatedAccount).then(_ => updatedAccount)
        })
    )
  )

export const fetchAccount = (accountId: OpportunitySfId) =>
  fromPromiseCatch(() => db.accounts.where('id').equals(accountId).toArray()).map(
    (accounts: OpportunityCustomer[]) => R.head(accounts) || {}
  )

export const getOffers = () => fromPromiseCatch(() => db.offers.toCollection().toArray())

const getOfferByOpportunityIdPromise = (opportunityId: OpportunitySfId) => {
  if (R.isNil(opportunityId)) {
    return new Dexie.Promise<ClientOffer>((_, reject) => {
      reject('missing opportunityId parameter')
    })
  }
  return db.offers
    .where('opportunityId')
    .equals(opportunityId)
    .toArray()
    .then(xs => R.head(xs || []))
}
const checkForNewerFinalizationProgress = (
  finalizationProgress: Record<string, string> | undefined,
  newProgress: Record<string, string>,
  finalized: boolean
) =>
  !finalized &&
  (!(finalizationProgress && finalizationProgress.percentComplete) ||
    parseInt(finalizationProgress.percentComplete, 10) < parseInt(newProgress.percentComplete, 10))

const shouldUpdateOffer = (dbOffer: Partial<ClientOffer>, updatedOffer: ClientOffer) => {
  const { finalizationProgress, finalized } = dbOffer
  const { finalizationProgress: newProgress, finalized: hasFinalized } = updatedOffer

  return (
    (hasFinalized && !finalized) ||
    !newProgress ||
    !Object.keys(newProgress).length ||
    checkForNewerFinalizationProgress(finalizationProgress, newProgress, !!finalized)
  )
}

const getUpdatedOfferData = (dbOffer: ClientOffer | undefined, updatedOffer: ClientOffer, forceUpdate = false) =>
  forceUpdate || shouldUpdateOffer(dbOffer || { finalized: false, finalizationProgress: {} }, updatedOffer)
    ? db.offers.put(updatedOffer).then(_ => updatedOffer)
    : dbOffer

export const updateOffer = (offer: Offer, forceUpdate = false) => {
  const { opportunityId } = offer
  return fromPromiseCatch(() =>
    db.transaction('rw', db.offers, () =>
      db.offers
        .where('opportunityId')
        .equals(opportunityId)
        .toArray()
        .then(xs => R.head(xs || []))
        .then(dbOffer =>
          getUpdatedOfferData(
            dbOffer,
            R.merge(
              { ...offer, clientId: opportunityId },
              dbOffer != null ? { lastSyncStarted: dbOffer.lastSyncStarted } : {}
            ),
            forceUpdate
          )
        )
    )
  )
}

export const getOfferByOpportunityId = (opportunityId: OpportunitySfId) =>
  fromPromiseCatch(() => getOfferByOpportunityIdPromise(opportunityId))

export const getUnsavedOffers = () =>
  fromPromiseCatch(() =>
    db.offers
      .toCollection()
      .and(isOfferUnsaved)
      .and(R.complement(isOfferFinalized))
      .and(R.complement(offerIsSyncing))
      .toArray()
  )

export const updateOfferDetails = (
  opportunityId: OpportunitySfId,
  details: Partial<ClientOffer>,
  forceUpdate = false
) =>
  getOfferByOpportunityId(opportunityId).chain(offer => {
    if (offer === undefined) {
      throw new Error('Offer not found with opportunityId: ' + opportunityId)
    }
    const previousVersionIndex = R.findIndex(({ id }) => id === offer.id, offer.previousVersions)
    const previousVersionLens = R.lensPath<ClientOffer, OfferPreviousVersion[]>([
      'previousVersions',
      previousVersionIndex
    ])

    const updatedOffer = R.pipe<ClientOffer, ClientOffer, ClientOffer>(
      o => R.merge(o, details),
      o => (previousVersionIndex === -1 ? o : R.over(previousVersionLens, version => R.merge(version, details), o))
    )(offer)

    return updateOffer(updatedOffer, forceUpdate)
  })

/* Updates offer with given function */
export const updateOfferWith = (opportunityId: OpportunitySfId, f: (offer: ClientOffer) => ClientOffer) =>
  getOfferByOpportunityId(opportunityId).chain(offer => updateOffer(f(offer!)))

export const updateOfferSAPDetails = (opportunityId: string) =>
  getOfferByOpportunityId(opportunityId).chain((offer: ClientOffer) =>
    updateOffer({ ...offer, sapOIStartTimestamp: null }, true)
  )
