import * as R from 'ramda'
import {
  EquipmentCategory,
  FlexibleScope,
  Grouping,
  Offer,
  RatingsAndSelections,
  QuickTenderScope,
  EquipmentGroup,
  EquipmentGroups,
  ValueAddedServicesAddendumScope,
  GroupKind,
  GSMCampaignScope
} from '../../../../types/types'
import { setContractType } from '../dependencies/contract-type'
import {
  Flexible,
  FlexibleCriticalities,
  FlexibleRatingsAndSelections,
  QuickTenderScopePending,
  Offering,
  QuickTenderEquipmentCategory,
  QuickTenderWithRatings,
  QuickTenderGroupWithRating,
  QuickTender,
  ValueAddedServicesAddendum,
  ElevatorsEscalatorsApfs,
  Doors,
  DoorGroups,
  Groups,
  ElevatorEscalatorApfGroups,
  Interaction,
  ResiFlowInteraction
} from './types'
import * as FlexGroups from './flexible'

function quickTenderWithRatingsToOffer(offer: Offer, offering: QuickTenderWithRatings) {
  function categoryToGrouping(scope: QuickTenderScope, category: QuickTenderEquipmentCategory): Grouping {
    const record: Grouping = {}
    if (category.normal) {
      record.normal = {
        ratings: {
          rating: category.normal.flexibleRating,
          basics: scope,
          technical: scope,
          performance: scope,
          value_added_services: scope,
          extra_services: scope,
          resiflow: null,
          gsm_campaign: scope // TODO: Remove as part of GSM Clean up
        },
        selections: category.normal.selections
      }
    }
    return record
  }

  return {
    ...offer,
    interaction: offering.interactionSelections,
    interactionRating: offering.scope,
    quickTenderScope: offering.scope,
    equipmentGroups: R.map(
      group => ({
        salesToolId: group.salesToolId,
        name: group.name,
        groupKind: group.groupKind,
        elevator: categoryToGrouping(offering.scope, group.selections)
      }),
      offering.groups
    ),
    priceEstimation: offer.priceEstimation
  }
}

function flexibleToOffer(offer: Offer, offering: Flexible): Offer {
  function buildRatingsAndSelections(ratings: FlexibleRatingsAndSelections) {
    return {
      ratings: {
        rating: ratings.ratings.rating,
        basics: ratings.ratings.moduleScopes.basics,
        technical: ratings.ratings.moduleScopes.technical,
        performance: ratings.ratings.moduleScopes.performance,
        value_added_services: ratings.ratings.moduleScopes.value_added_services,
        extra_services: ratings.ratings.moduleScopes.extra_services,
        resiflow: ratings.ratings.moduleScopes.resiflow,
        gsm_campaign: ratings.ratings.moduleScopes.gsm_campaign // TODO: Remove as part of GSM Clean up
      },
      selections: setContractType(ratings.selections)
    }
  }

  function categoryToGrouping(category: FlexibleCriticalities): Grouping {
    const record: Grouping = {}
    if (category.normal) {
      record.normal = buildRatingsAndSelections(category.normal)
    }
    return record
  }

  function handleElevatorEscalatorApfGroups(groups: Groups<ElevatorsEscalatorsApfs>) {
    return R.map(group => {
      const equipmentCategories = [
        group.equipment.elevator
          ? (['elevator', categoryToGrouping(group.equipment.elevator)] as [EquipmentCategory, Grouping])
          : null,
        group.equipment.escalator
          ? (['escalator', categoryToGrouping(group.equipment.escalator)] as [EquipmentCategory, Grouping])
          : null,
        group.equipment.resiflow
          ? (['resiflow', categoryToGrouping(group.equipment.resiflow)] as [EquipmentCategory, Grouping])
          : null
      ]
      return {
        salesToolId: group.salesToolId,
        name: group.name,
        groupKind: group.groupKind,
        ...R.fromPairs(collect(equipmentCategories))
      }
    }, groups)
  }

  function handleDoorGroups(groups: Groups<Doors>) {
    return R.map(group => {
      const equipmentCategories = [
        group.equipment.door
          ? (['door', categoryToGrouping(group.equipment.door)] as [EquipmentCategory, Grouping])
          : null
      ]
      return {
        salesToolId: group.salesToolId,
        name: group.name,
        groupKind: 'doors' as GroupKind,
        ...R.fromPairs(collect(equipmentCategories))
      }
    }, groups)
  }

  return {
    ...offer,
    equipmentGroups: FlexGroups.match(offering.groups, {
      DoorGroups: handleDoorGroups,
      ElevatorEscalatorApfGroups: handleElevatorEscalatorApfGroups
    }),
    interaction: offering.interaction ? offering.interaction.selections : {},
    interactionRating: offering.interaction ? offering.interaction.scope : null,
    quickTenderScope: 'flexible',
    priceEstimation: offer.priceEstimation
  }
}

// TODO refactor, take in the groups from assessment result
export function fromDomain(offer: Offer, offering: Offering): Offer {
  switch (offering._tag) {
    case 'NoRatings':
    case 'QuickTenderScopePending':
      // Just update whatever groups there are in the offer
      // and remove their ratings
      return {
        ...offer,
        interaction: {},
        interactionRating: null,
        quickTenderScope: null,
        equipmentGroups: R.map(
          g => ({
            salesToolId: g.salesToolId,
            name: g.name,
            groupKind: g.groupKind
          }),
          offer.equipmentGroups
        )
      }
    case 'QuickTender':
      // Set all group ratings to the same scope
      return {
        ...offer,
        interaction: offering.interactionSelections,
        interactionRating: offering.scope,
        quickTenderScope: offering.scope,
        equipmentGroups: R.map(
          g => ({
            salesToolId: g.salesToolId,
            name: g.name,
            groupKind: g.groupKind,
            elevator: {
              normal: {
                ratings: {
                  rating: null,
                  basics: offering.scope,
                  technical: offering.scope,
                  performance: offering.scope,
                  value_added_services: offering.scope,
                  extra_services: offering.scope,
                  resiflow: null,
                  gsm_campaign: offering.scope // TODO: Remove as part of GSM Clean up
                },
                selections: offering.groups[g.salesToolId].selections
              }
            }
          }),
          offer.equipmentGroups
        )
      }
    case 'QuickTenderWithRatings':
      return quickTenderWithRatingsToOffer(offer, offering)
    case 'Flexible':
      return flexibleToOffer(offer, offering)
    case 'ValueAddedServicesAddendum':
      return offer.isGSMOffer
        ? {
            // TODO: Remove as part of GSM Clean up
            ...offer,
            interaction: {},
            interactionRating: 'gsm_campaign',
            equipmentGroups: R.map(
              g => ({
                salesToolId: g.salesToolId,
                name: g.name,
                groupKind: g.groupKind,
                elevator: {
                  normal: {
                    ratings: {
                      rating: null,
                      basics: null,
                      technical: null,
                      performance: null,
                      value_added_services: null,
                      extra_services: null,
                      resiflow: null,
                      gsm_campaign: 'gsm_campaign' as GSMCampaignScope
                    },
                    selections: offering.groups[g.salesToolId].selections
                  }
                }
              }),
              offer.equipmentGroups
            ),
            quickTenderScope: 'gsm_campaign'
          }
        : {
            ...offer,
            interaction: offering.interactionSelections,
            interactionRating: 'value_added_services_addendum',
            equipmentGroups: R.map(
              g => ({
                salesToolId: g.salesToolId,
                name: g.name,
                groupKind: g.groupKind,
                elevator: {
                  normal: {
                    ratings: {
                      rating: null,
                      basics: null,
                      technical: null,
                      performance: null,
                      value_added_services: 'value_added_services_addendum' as ValueAddedServicesAddendumScope,
                      extra_services: null,
                      resiflow: null,
                      gsm_campaign: null
                    },
                    selections: offering.groups[g.salesToolId].selections
                  }
                }
              }),
              offer.equipmentGroups
            ),
            quickTenderScope: 'value_added_services_addendum'
          }
    case 'gsm_campaign': // TODO: Remove as part of GSM Clean up
      return {
        ...offer,
        interaction: offering.interactionSelections,
        interactionRating: 'gsm_campaign',
        equipmentGroups: R.map(
          g => ({
            salesToolId: g.salesToolId,
            name: g.name,
            groupKind: g.groupKind,
            elevator: {
              normal: {
                ratings: {
                  rating: null,
                  basics: null,
                  technical: null,
                  performance: null,
                  extra_services: null,
                  resiflow: null,
                  value_added_services: null,
                  gsm_campaign: null
                },
                selections: offering.groups[g.salesToolId].selections
              }
            }
          }),
          offer.equipmentGroups
        ),
        quickTenderScope: 'gsm_campaign' // TODO: Remove as part of GSM Clean up
      }
  }
}

export function toDomain(offer: Offer): Offering {
  function buildRatingsAndSelections(ratings: RatingsAndSelections): FlexibleRatingsAndSelections {
    return {
      ratings: {
        rating: ratings.ratings.rating as FlexibleScope,
        moduleScopes: {
          basics: ratings.ratings.basics as FlexibleScope,
          technical: ratings.ratings.technical as FlexibleScope,
          performance: ratings.ratings.performance as FlexibleScope,
          value_added_services: ratings.ratings.value_added_services as FlexibleScope,
          extra_services: ratings.ratings.extra_services as FlexibleScope,
          resiflow: ratings.ratings.resiflow as FlexibleScope,
          gsm_campaign: ratings.ratings.gsm_campaign as FlexibleScope // TODO: Remove as part of GSM Clean up
        }
      },
      selections: ratings.selections
    }
  }

  function buildEquipmentCategory(group: Grouping): FlexibleCriticalities {
    return {
      normal: group.normal ? buildRatingsAndSelections(group.normal) : null,
      critical: null // group.critical ? buildRatingsAndSelections(group.critical) : null
    }
  }

  function handleElevatorEscalatorApfGroups(equipmentGroups: EquipmentGroups) {
    return R.map(group => {
      const equipment = ElevatorsEscalatorsApfs.fromObject({
        elevator: group.elevator ? buildEquipmentCategory(group.elevator) : null,
        escalator: group.escalator ? buildEquipmentCategory(group.escalator) : null,
        resiflow: group.resiflow ? buildEquipmentCategory(group.resiflow) : null
      })

      return {
        salesToolId: group.salesToolId,
        name: group.name,
        groupKind: group.groupKind,
        equipment
      }
    }, equipmentGroups)
  }

  function handleDoorGroups(equipmentGroups: EquipmentGroups) {
    return R.map(group => {
      const equipment = Doors.fromObject(
        group.door ? { door: buildEquipmentCategory(group.door) } : { door: { normal: null, critical: null } } // Should basically fail here
      )

      return {
        salesToolId: group.salesToolId,
        name: group.name,
        groupKind: group.groupKind,
        equipment
      }
    }, equipmentGroups)
  }

  if (
    offer.quickTenderScope === 'value_added_services_addendum' ||
    offer.opportunity.opportunityCategory === 'Value Added Service'
  ) {
    return new ValueAddedServicesAddendum(
      R.map(
        group => ({
          salesToolId: group.salesToolId,
          name: group.name,
          groupKind: group.groupKind,
          selections: (group.elevator && group.elevator.normal && group.elevator.normal.selections) || {}
        }),
        offer.equipmentGroups
      ),
      offer.interaction
    )
  } else if (offer.quickTenderScope === 'flexible') {
    const hasElevatorsOrEscalatorsOrApf = R.any(
      g => !!(g.elevator || g.escalator || g.resiflow),
      R.values(offer.equipmentGroups)
    )

    const groups = hasElevatorsOrEscalatorsOrApf
      ? new ElevatorEscalatorApfGroups(handleElevatorEscalatorApfGroups(offer.equipmentGroups))
      : new DoorGroups(handleDoorGroups(offer.equipmentGroups))

    return offer.interactionRating
      ? new Flexible(groups, {
          scope: offer.interactionRating,
          selections: offer.interaction
        } as Interaction)
      : new Flexible(groups, null as ResiFlowInteraction)
  } else if (offer.quickTenderScope === null) {
    // Only the technical details were filled in,
    // no ratings yet
    return new QuickTenderScopePending(
      R.map(
        g => ({
          salesToolId: g.salesToolId,
          name: g.name,
          groupKind: g.groupKind
        }),
        offer.equipmentGroups
      )
    )
  } else if (offer.quickTenderScope === 'kone_care_taker' || offer.quickTenderScope === 'konexion') {
    // A quick tender, either:
    // * the offer has recommended ratings (QuickTenderWithRatings)
    // * or not (QuickTender)
    if (hasGroups(offer.equipmentGroups) && offer.selectedInteractionRating) {
      return new QuickTenderWithRatings(
        R.map(toGroup, offer.equipmentGroups),
        offer.quickTenderScope,
        offer.interaction,
        offer.selectedInteractionRating
      )
    } else {
      return new QuickTender(
        R.map(
          g =>
            g.elevator && g.elevator.normal
              ? {
                  salesToolId: g.salesToolId,
                  name: g.name,
                  groupKind: g.groupKind,
                  selections: g.elevator.normal.selections
                }
              : {
                  salesToolId: g.salesToolId,
                  name: g.name,
                  groupKind: g.groupKind,
                  selections: {}
                },
          offer.equipmentGroups
        ),
        offer.quickTenderScope,
        offer.interaction
      )
    }
  } else {
    throw new Error('Invalid offer')
  }
}

function hasGroups(groups: EquipmentGroups): boolean {
  return R.all(
    g => (g.elevator ? hasRatings(g.elevator) : g.escalator ? hasRatings(g.escalator) : true),
    R.values(groups)
  )
}

function hasRatings(grouping: Grouping) {
  return grouping.normal ? grouping.normal.ratings.rating != null : true // : grouping.critical
  // ? grouping.critical.ratings.rating != null
}

function toGroup(group: EquipmentGroup): QuickTenderGroupWithRating {
  const category = (g: Grouping | undefined): QuickTenderEquipmentCategory => {
    if (!g) {
      return { normal: null, critical: null }
    }
    return {
      normal: g.normal
        ? {
            flexibleRating: g.normal.ratings.rating as FlexibleScope,
            selections: g.normal.selections
          }
        : null,
      critical: null
    }
  }

  return {
    salesToolId: group.salesToolId,
    name: group.name,
    groupKind: group.groupKind,
    selections: category(group.elevator)
  }
}

function collect<A>(xs: Array<A | null>): A[] {
  return R.chain(n => (n != null ? [n] : []), xs)
}
