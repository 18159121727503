import * as React from 'react'
import { t } from 'i18next'
import * as ClassNames from 'classnames'
import { hide } from '../../../nemo/dialog'
import SearchBox from '../../Opportunities/SearchBox'

interface Props {
  title: string
  onClose?: () => void
  onPrimary?: () => void
  onCancel?: () => void
  searchBoxUpdated?: (searchTerm: string) => void
  showPrimaryButton?: boolean
  primaryButtonTitle?: string
  disablePrimaryButton?: boolean
  showCancelButton?: boolean
  cancelButtonTitle?: string
  footerText?: string
  disableClosing?: boolean
  flexBody?: boolean
  displaySearchBox?: boolean
  // Todo remove this
  useLargeDialog?: boolean
  modalClassName?: string
  setupFeeTotal?: JSX.Element
  width?: string
  extraButtonOptions?: Array<{
    key: string
    className?: string
    label: JSX.Element | string
    onClick(): void
  }>
  autoClose?: boolean
}

interface State {
  isClosed: boolean
  searchTerm: string
}

export default class Dialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isClosed: false,
      searchTerm: ''
    }
    this.searchUpdated = this.searchUpdated.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onPrimary = this.onPrimary.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.close = this.close.bind(this)
  }

  searchUpdated(searchTerm: string): void {
    this.setState({ searchTerm })
    this.props.searchBoxUpdated && this.props.searchBoxUpdated(searchTerm)
  }

  close() {
    const { autoClose = true } = this.props
    if (autoClose) {
      this.setState({ isClosed: true })
      hide()
    }
  }

  onClose() {
    if (this.props.onClose) {
      this.props.onClose()
    }
    if (!this.props.disableClosing) {
      this.close()
    }
  }

  onPrimary() {
    const cb = this.props.onPrimary
    this.close()
    if (cb) {
      cb()
    }
  }

  onCancel() {
    // Need to regression test as in common file
    if (this.props.onCancel) {
      this.close()
      this.props.onCancel()
    } else {
      this.close()
    }
  }

  render() {
    if (this.state.isClosed) {
      return null
    }
    const { extraButtonOptions = [], disableClosing } = this.props
    // In some cases we might not have translations if i18n is not yet
    // initialized. This might occur in very early stages of application
    // startup
    const primaryBtnLabel = this.props.primaryButtonTitle || t('errors.modalOK') || 'Ok'
    const cancelBtnLabel = this.props.cancelButtonTitle || t('errors.modalCancel') || 'Cancel'

    let style = {}
    if (this.props.useLargeDialog) {
      style = { height: '600px', width: this.props.width || '700px' }
    }
    const { setupFeeTotal, displaySearchBox } = this.props
    const isSearchBox = displaySearchBox ? 'column is-4' : ''

    return (
      <div className={`modal is-active ${this.props.modalClassName}`}>
        <div className="modal-background" onClick={this.onClose} />
        <div className="modal-card" style={style}>
          <header className="modal-card-head">
            <div className="modal-header-container">
              <div className={`vas-modal-title${isSearchBox ? ' modal-search-title' : ''}`}>
                <p className={ClassNames(`modal-card-title${isSearchBox ? ` ${isSearchBox}` : ''}`)}>
                  {this.props.title}
                </p>
                {displaySearchBox ? (
                  <div className={ClassNames(`is-hidden-mobile-only ${isSearchBox}`)}>
                    <SearchBox onChange={this.searchUpdated} queryLength={1} />
                  </div>
                ) : null}
                {!disableClosing && (
                  <button
                    className={ClassNames(`modal-close-btn icon icon-cross${isSearchBox ? ` ${isSearchBox}` : ''}`)}
                    aria-label="close"
                    onClick={this.onClose}
                  />
                )}
              </div>
              {displaySearchBox ? (
                <div className="is-hidden-desktop">
                  <SearchBox onChange={this.searchUpdated} queryLength={1} />
                </div>
              ) : null}
              {setupFeeTotal ? setupFeeTotal : null}
            </div>
          </header>
          <section className={ClassNames('modal-card-body', { 'is-flex': this.props.flexBody })}>
            {this.props.children}
          </section>
          {this.props.showCancelButton || this.props.showPrimaryButton || this.props.footerText ? (
            <footer className="modal-card-foot">
              <div className="modal-card-foot-text">{this.props.footerText}</div>
              <div className="modal-card-foot-buttons">
                {extraButtonOptions.map(({ label, key, ...rest }) => (
                  <button key={key} {...rest}>
                    {label}
                  </button>
                ))}
                {this.props.showCancelButton ? (
                  <button className="button" onClick={this.onCancel}>
                    {cancelBtnLabel}
                  </button>
                ) : null}
                {this.props.showPrimaryButton ? (
                  <button
                    disabled={this.props.disablePrimaryButton}
                    className="button is-primary"
                    onClick={this.onPrimary}
                  >
                    {primaryBtnLabel}
                  </button>
                ) : null}
              </div>
            </footer>
          ) : null}
        </div>
      </div>
    )
  }
}
