import { Offering, CriticalityPath } from './types'
import { Module, BundleGroupSelection, SapKey } from '../../../../types/types'

// This datatype is for signaling what updates needs to be
// done to the dependencies after a domain event happens.
//
// * NoOp: no changes required
// * ValidateAll: dependencies should be recalculated for all groups and interaction
// * ValidateGroup: dependencies should be recalculated for one group only and interaction
export type StateChange = NoOp | ValidateAll | ValidateGroup | ValidateBundle

export class NoOp {
  readonly _tag: 'UpdateNothing' = 'UpdateNothing'
  constructor(readonly offering: Offering, readonly error: StateUpdateError | null = null) {}
}

export class ValidateAll {
  readonly _tag: 'UpdateAll' = 'UpdateAll'
  constructor(readonly offering: Offering) {}
}

export class ValidateBundle {
  readonly _tag: 'UpdateBundleSelections' = 'UpdateBundleSelections'
  constructor(
    readonly offering: Offering,
    readonly path: CriticalityPath,
    readonly bundleServiceSelectionChange: BundleGroupSelection
  ) {}
}
export class ValidateGroup {
  readonly _tag: 'UpdateGroup' = 'UpdateGroup'
  constructor(readonly path: CriticalityPath, readonly changedModule: Module, readonly offering: Offering) {}
}

export class BundleUpdateError extends Error {
  readonly _tag = 'BundleUpdateError'
  constructor(
    readonly keys: SapKey[],
    readonly data: { bundleKey: string; groupId: string },
    readonly message: string
  ) {
    super(message)
  }
}
BundleUpdateError.prototype.name = 'BundleUpdateError'

export type StateUpdateError = BundleUpdateError
