import { TranslationFunction } from 'i18next'
import { sanitizeSalesforceTranslationKey } from './common-utils'

export const getCommercialTermChoiceTranslation =
  (t: TranslationFunction<string | undefined>) =>
  (key: string, value: string | number | null | undefined): string | undefined =>
    t(`commercialTerms:${key}#choices#${value}`, { keySeparator: '#' })

export const translateCommercialTermKey = (t: TranslationFunction) => (key: string) =>
  t(`commercialTerms:${key}.title`) || key

export const sfTranslations = (i18nPath: string, value: any, t: TranslationFunction) =>
  t(`${i18nPath}${sanitizeSalesforceTranslationKey(value)}`) || value

export const translateOfferingQuestionTitle = (t: TranslationFunction) => (key: string) =>
  t(`offerSelections:${key}.title`)

export const translateOfferingAnswer = (t: TranslationFunction) => (key: string, answer: string) =>
  t(`offerSelections:${key}#choices#${answer}#title`, { keySeparator: '#' })
